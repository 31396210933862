import React from "react"
import { useHistory, useParams } from "react-router-dom"

import {
    ActionBar,
    ActionButtonProps,
    BreadcrumbProps,
    IconType,
} from "../../../components/action-bar/ActionBar.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { InformationLabel } from "../../../components/label/Label.component"
import {
    ConfirmationModalWithoutRef,
    ModalApi,
    ModalProps,
} from "../../../components/modal/ConfirmationModal.component"
import { OverviewStatus } from "../../../components/overview/OverviewTopContainer/OverviewStatus.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { TabBar, Tab as TabProps } from "../../../pre-v3/components/tab-bar/TabBar.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { useAuthService } from "../../../pre-v3/services/Auth.service"
import { decodeID, encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { formatToLocalDateTimeStr } from "../../../utils/Date.utils"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { StatusType } from "../../../v3/components/status/Status.component"
import {
    Org,
    Status as OrgStatus,
    statusToLabelDict,
    useArchiveOrg,
    useDeleteOrg,
    useGetOrgById,
    useGenerateImpersonateToken,
    useUnarchiveOrg,
} from "../../../v3/services/MomOrgManagement.service"
import { OrgManagementStatusForm } from "../../../v3/views/org-management/form/OrgManagementStatusForm.component"
import { OrgSpecification } from "./OrgSpecification.component"
import styles from "./OrgDetails.module.scss"
import {
    KeyValuePair,
    KeyValuePairList,
} from "../../../components/key-value-pair/KeyValuePair.component"
import { OrgSupportBundlesTab } from "./OrgSupportBundlesTab.component"

interface Props {
    canDeleteOrg: boolean
}

export function OrgDetails(props: Props): JSX.Element {
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()

    const {
        data: org,
        status: orgStatus,
        error: orgError,
        refetch: refetchOrg,
    } = useGetOrgById(decodeID(params.id))

    switch (orgStatus) {
        case "loading":
            return (
                <Loader
                    title={localization.getString(
                        "loadingSomething",
                        localization.getString("orgDetails")
                    )}
                    center
                    medium
                />
            )

        case "error":
            return <Banner label={String(orgError)} variant={Variant.ERROR} />

        case "success":
            return (
                <AfterLoading org={org} canDeleteOrg={props.canDeleteOrg} onRefresh={refetchOrg} />
            )
    }
}

interface AfterLoadingProps {
    org: Org
    canDeleteOrg: boolean
    onRefresh(): void
}

function AfterLoading(props: AfterLoadingProps): JSX.Element {
    const authService = useAuthService()
    const history = useHistory()
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    const confirmImpersonationModalRef = React.useRef<ModalApi<void>>(null)
    const confirmArchivalModalRef = React.useRef<ModalApi<void>>(null)
    const confirmDeletionModalRef = React.useRef<ModalApi<void>>(null)
    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const onError = (error: unknown) =>
        typeof error === "string" ? errorToastRef.current?.openToast(error) : console.error(error)

    const { mutateAsync: impersonate, isLoading: isImpersonating } = useGenerateImpersonateToken(
        props.org,
        {
            onSuccess: (jwtToken) => {
                authService.impersonateFromMomConsole(jwtToken)
                history.push(ROUTE.HOME)
            },
            onError,
        }
    )

    const { mutateAsync: archiveOrg, isLoading: isArchiving } = useArchiveOrg(props.org, {
        onError,
    })

    const { mutate: unarchiveOrg, isLoading: isUnarchiving } = useUnarchiveOrg(props.org, {
        onError,
    })

    const { mutateAsync: deleteOrg, isLoading: isDeleting } = useDeleteOrg(props.org, {
        onSuccess: () => {
            successToastRef.current?.openToast(
                localization.getString("aDeletionWasRequestedForThisOrg", props.org.name)
            )
        },
        onError,
    })

    const actions = useActions({
        org: props.org,
        canDeleteOrg: props.canDeleteOrg,
        disableAllActions: isImpersonating || isArchiving || isUnarchiving || isDeleting,
        onImpersonate: () => {
            confirmImpersonationModalRef.current?.open()
        },
        onArchive: () => {
            confirmArchivalModalRef.current?.open()
        },
        onDelete: () => {
            confirmDeletionModalRef.current?.open()
        },
        onUnarchive: unarchiveOrg,
        onRefresh: props.onRefresh,
    })

    const [activeTab, setActiveTab] = React.useState(Tab.SPECIFICATION)

    const breadcrumbs: BreadcrumbProps[] = [
        {
            label: localization.getString("superAdmin"),
        },
        {
            label: localization.getString("orgManagement"),
            href: ROUTE.ORG_MANAGEMENT,
        },
    ]

    const tabs: TabProps[] = [
        {
            label: localization.getString("specification"),
            value: Tab.SPECIFICATION,
            active: activeTab === Tab.SPECIFICATION,
        },
        {
            label: localization.getString("status"),
            value: Tab.STATUS,
            active: activeTab === Tab.STATUS,
        },
        {
            label: localization.getString("supportBundles"),
            value: Tab.SUPPORT_BUNDLES,
            active: activeTab === Tab.SUPPORT_BUNDLES,
        },
    ]

    const adminLabel = localization.getString("admin")

    return (
        <React.Fragment>
            <ActionBar
                title={
                    <div key={props.org.id} className={styles.orgName}>
                        {props.org.name}
                        {props.org.featureFlags.isSonicWallProvisioned && (
                            <InformationLabel>
                                {localization.getString("sonicWallProvisioned")}
                            </InformationLabel>
                        )}
                    </div>
                }
                breadcrumbs={breadcrumbs}
                actions={actions}
            />
            <div className={styles.overviewContainer}>
                <div className={styles.overviewLeftContainer}>
                    <OverviewStatus
                        statusType={statusMapForComponent[props.org.status]}
                        statusLabel={localization.getString(statusToLabelDict[props.org.status])}
                        statusItems={[
                            {
                                label: localization.getString("lastUpdated"),
                                value: formatToLocalDateTimeStr(props.org.lastUpdatedAt, locale),
                            },
                        ]}
                        className={styles.statusContainer}
                    />
                    <KeyValuePairList className={styles.detailsContainer}>
                        <KeyValuePair
                            label={localization.getString("createdAt")}
                            value={formatToLocalDateTimeStr(props.org.createdAt, locale)}
                        />
                        <KeyValuePair
                            label={localization.getString("createdBy")}
                            value={props.org.createdBy}
                        />
                        <KeyValuePair
                            label={localization.getString("lastUpdatedBy")}
                            value={props.org.lastUpdatedBy}
                        />
                    </KeyValuePairList>
                </div>
                <div className={styles.overviewRightContainer}>
                    <TabBar tabs={tabs} onChange={setActiveTab} />
                    <div className={styles.tabContainer}>
                        <TabContent activeTab={activeTab} org={props.org} />
                    </div>
                </div>
            </div>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
            <ConfirmationModal
                id={Id.CONFIRM_IMPERSONATION_MODAL}
                title={localization.getString("confirmViewAsSomething", adminLabel)}
                confirmButtonLabel={localization.getString("viewAsSomething", adminLabel)}
                onConfirm={async () => {
                    await impersonate()
                }}
                ref={confirmImpersonationModalRef}
            >
                {localization.getString("confirmViewAsAdminExplanation", adminLabel)}
            </ConfirmationModal>
            <ConfirmationModal
                id={Id.CONFIRM_ARCHIVAL_MODAL}
                title={localization.getString("archiveOrg")}
                confirmButtonLabel={localization.getString("archive")}
                isDestructive
                onConfirm={async () => {
                    await archiveOrg()
                    confirmArchivalModalRef.current?.complete()
                }}
                ref={confirmArchivalModalRef}
            >
                {localization.getString("areYouSureYouWantToArchiveThisOrg")}
            </ConfirmationModal>
            <ConfirmationModal
                id={Id.CONFIRM_DELETION_MODAL}
                title={localization.getString("deleteOrg")}
                confirmButtonLabel={localization.getString("delete")}
                isDestructive
                onConfirm={async () => {
                    await deleteOrg()
                    confirmDeletionModalRef.current?.complete()
                }}
                ref={confirmDeletionModalRef}
            >
                {localization.getString("areYouSureYouWantToDeleteThisOrg")}
            </ConfirmationModal>
        </React.Fragment>
    )
}

interface ActionProps {
    org: Org
    canDeleteOrg: boolean
    disableAllActions: boolean
    onImpersonate(): void
    onArchive(): void
    onUnarchive(): void
    onDelete(): void
    onRefresh(): void
}

function useActions(props: ActionProps): ActionButtonProps[] {
    const localization = useServiceLocalization()

    const impersonateAction: ActionButtonProps = {
        label: localization.getString("viewAsSomething", localization.getString("admin")),
        icon: IconType.USER,
        onClick: props.onImpersonate,
        disabled: props.disableAllActions,
    }

    const unarchiveAction: ActionButtonProps = {
        label: localization.getString("unarchive"),
        icon: IconType.BOX_OPEN,
        onClick: props.onUnarchive,
        disabled: props.disableAllActions,
    }

    const archiveAction: ActionButtonProps = {
        label: localization.getString("archive"),
        icon: IconType.BOX_CLOSED,
        onClick: props.onArchive,
        disabled: props.disableAllActions,
    }

    const deleteAction: ActionButtonProps = {
        label: localization.getString("delete"),
        icon: IconType.TRASH,
        onClick: props.onDelete,
        disabled: props.disableAllActions,
    }

    const editAction: ActionButtonProps = {
        label: localization.getString("edit"),
        icon: IconType.PEN,
        href: formatRoutePath(ROUTE.ORG_MANAGEMENT_EDIT, { id: encodeID(props.org.id) }),
        disabled: props.disableAllActions,
    }

    const refreshAction: ActionButtonProps = {
        label: localization.getString("refresh"),
        onClick: props.onRefresh,
        icon: IconType.REDO,
    }

    return [
        impersonateAction,
        ...(props.org.featureFlags.isSonicWallProvisioned
            ? []
            : [props.org.featureFlags.isArchived ? unarchiveAction : archiveAction]),
        ...(props.canDeleteOrg ? [deleteAction] : []),
        editAction,
        refreshAction,
    ]
}

// TODO: Use string values once we use the new TabBar
enum Tab {
    SPECIFICATION = 1,
    STATUS,
    SUPPORT_BUNDLES,
}

interface TabContentProps {
    activeTab: Tab
    org: Org
}

function TabContent(props: TabContentProps): JSX.Element {
    switch (props.activeTab) {
        case Tab.SPECIFICATION:
            return <OrgSpecification org={props.org} />
        case Tab.STATUS:
            return <OrgManagementStatusForm org={props.org} />
        case Tab.SUPPORT_BUNDLES:
            return <OrgSupportBundlesTab org={props.org} />
    }
}

const ConfirmationModal = React.forwardRef<ModalApi<void>, ModalProps>(ConfirmationModalWithoutRef)

enum Id {
    CONFIRM_IMPERSONATION_MODAL = "confirmImpersonationModal",
    CONFIRM_ARCHIVAL_MODAL = "confirmArchivalModal",
    CONFIRM_DELETION_MODAL = "confirmDeletionModal",
}

const statusMapForComponent: Record<OrgStatus, StatusType> = {
    [OrgStatus.ACTIVE]: "success",
    [OrgStatus.PARTIAL_SUCCESS]: "warning",
    [OrgStatus.ERROR]: "error",
    [OrgStatus.IN_PROGRESS]: "disabled",
    [OrgStatus.UNKNOWN]: "unknown",
}
