import classNames from "classnames/bind"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../routes"
import { useActivityMonitor } from "../../hooks/useActivityMonitor.hook"
import { useProtectPages } from "../../hooks/useProtectPages.hook"
import { useSoftwareVersion } from "../../hooks/useSoftwareVersion.hook"
import useTitle from "../../hooks/useTitle.hook"
import { TopNav } from "../../pre-v3/components/top-nav/TopNav"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { AppNavLink } from "../../pre-v3/services/shared/AppNavLink"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useGetUserProfile } from "../../v3/services/UserProfile.service"
import { MomOrgManagement } from "../mom-org-management/MomOrgManagement.page"
import styles from "./MomConsole.module.scss"
import { AppConsole } from "../../utils/consoles.utils"
import { Profile } from "../../utils/profile.utils"
import { MomSettings } from "../../pre-v3/views/settings/MomSettings.component"
import { NavBar } from "../../pre-v3/components/nav-bar/NavBar.component"
import { SystemLogs } from "../../pre-v3/views/monitor/system-logs/SystemLogs.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"

export function MomConsole(): JSX.Element {
    useActivityMonitor()
    useTitle(["momConsole"])
    const isLoading = useProtectPages(AppConsole.MOM)

    const softwareVersion = useSoftwareVersion()
    const userMenuLinks = useUserMenuLinks()
    const supportLinks = useSupportLinks()
    const topLinks = useTopLinks()
    const { data } = useGetUserProfile()
    const isSuperAdmin = data?.profile === Profile.SUPER_ADMIN
    const {
        data: featureFlags,
        isLoading: isFeatureFlagLoading,
        isError: isfeatureFlagsError,
        error: featureFlagError,
    } = useFeatureFlags()

    if (isLoading || isFeatureFlagLoading) return <Loader center medium />

    if (isfeatureFlagsError) {
        return <ErrorBanner>{String(featureFlagError)}</ErrorBanner>
    }
    return (
        <div className={classNames(styles.container, "pre-v3")}>
            <header className={styles.header}>
                <TopNav
                    logoLink="/"
                    userMenuLinks={userMenuLinks}
                    supportLinks={supportLinks}
                    fullName={data?.fullName ?? ""}
                    orgName={data?.orgName ?? ""}
                    appVersion={softwareVersion}
                    hideSettings={!isSuperAdmin}
                    settingsLink={ROUTE.MOM_SIGN_ON}
                >
                    <NavBar links={topLinks} />
                </TopNav>
            </header>
            <div className={styles.body}>
                <Switch>
                    <Route path={ROUTE.ORG_MANAGEMENT}>
                        <MomOrgManagement canDeleteOrg={featureFlags.momConsole.canDeleteOrg} />
                    </Route>
                    <Route path={ROUTE.MOM_SYSTEM_LOGS} component={SystemLogs} />
                    {isSuperAdmin && (
                        <Route path={ROUTE.MOM_SETTINGS}>
                            <MomSettings
                                canConfigureAdminSignOnSettings={
                                    featureFlags.momConsole.canConfigureAdminSignOnSettings
                                }
                                canAccessSignOn={featureFlags.momConsole.canAccessAdminSignOn}
                            />
                        </Route>
                    )}
                    <Redirect to={ROUTE.ORG_MANAGEMENT} />
                </Switch>
            </div>
        </div>
    )
}

function useTopLinks(): AppNavLink[] {
    const localization = useServiceLocalization()
    return [
        {
            label: localization.getString("orgManagement"),
            href: ROUTE.ORG_MANAGEMENT,
        },
        {
            label: localization.getString("systemLogs"),
            href: ROUTE.MOM_SYSTEM_LOGS,
        },
    ]
}

function useUserMenuLinks(): AppNavLink[] {
    const localization = useServiceLocalization()
    const authService = useAuthService()

    return [
        {
            label: localization.getString("signOut"),
            onClick: () => authService.logoutOfAllTabs(),
        },
    ]
}

function useSupportLinks(): AppNavLink[] {
    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    return [
        {
            label: localization.getString("documentation"),
            href: linkService.getLink("docSite"),
            target: "_blank",
        },
        {
            label: localization.getString("supportSite"),
            href: linkService.getLink("supportHome"),
            target: "_blank",
        },
        {
            label: localization.getString("fileATicket"),
            href: linkService.getLink("fileATicket"),
            target: "_blank",
            teamEditionHidden: true,
        },
        {
            label: localization.getString("communityForum"),
            href: linkService.getLink("communityForum"),
            target: "_blank",
            teamEditionOnly: true,
        },
    ]
}
