import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DeviceConfigRes, DeviceConfigApi, DeviceConfigReq } from "../api/DeviceConfig.api"
import {
    ProviderName,
    ProviderProtocol,
    noneDeviceProviderName,
} from "./shared/EndUserDeviceConfig"

const appClientName: string = "CSE DeviceRegistrationProvider"
export function useGetDeviceProviderConfig(
    options?: QueryOptions<DeviceConfig>
): UseQueryResult<DeviceConfig> {
    const deviceConfigApi = new DeviceConfigApi()
    return useQuery({
        ...options,
        queryKey: ["deviceConfigService.getDeviceProviderConfig"],
        queryFn: async (): Promise<DeviceConfig> => {
            const userOrgDetails = await deviceConfigApi.getUserOrgDetails()
            let deviceConfig: DeviceConfigRes | undefined
            try {
                deviceConfig = JSON.parse(userOrgDetails.DeviceRegIDPConfig)
            } catch {
                deviceConfig = undefined
            }

            const isCognitoIdp: boolean = userOrgDetails.DeviceRegIDPName === ProviderName.COGNITO
            if (isCognitoIdp) {
                deviceConfig = undefined
            }
            const trustProviderRedirectUrl: string =
                userOrgDetails.TrustproviderURL?.replace(
                    "https://",
                    `https://${userOrgDetails.OrgName}.`
                ) + "/v2/callback" ?? ""
            const redirectUrl: string = trustProviderRedirectUrl
                ? trustProviderRedirectUrl.replace(".trust", ".portal")
                : ""

            return {
                deviceConfig: {
                    ssoUrl: deviceConfig?.SSOURL || "",
                    redirectUrl: deviceConfig?.RedirectURL || redirectUrl,
                    caData: deviceConfig?.CAData || "",
                    userName: deviceConfig?.UsernameAttr || "",
                    email: deviceConfig?.EmailAttr || "",
                    group: deviceConfig?.GroupsAttr || "",
                    groupDelimiter: deviceConfig?.GroupsDelim || "",
                    entityIssuer: deviceConfig?.EntityIssuer || "",
                    clientId: deviceConfig?.ClientID || "",
                    clientSecret: deviceConfig?.ClientSecret || "",
                    appClientName: appClientName,
                    issuerUrl: deviceConfig?.IssuerURL || "",
                    accessKey: deviceConfig?.AccessKeyId || "",
                    secretKey: deviceConfig?.SecretAccessKey || "",
                    userPoolId: deviceConfig?.UserPoolId || "",
                    authDomain: deviceConfig?.UserPoolDomain || "",
                    idpName: deviceConfig ? userOrgDetails.DeviceRegIDPName : "",
                    idpProtocol: deviceConfig
                        ? (userOrgDetails.DeviceRegIDPProto as ProviderProtocol)
                        : ProviderProtocol.UNSET,
                },
                isAdminBanyanIdp: userOrgDetails.IsAdminBanyanIDP,
                isCognitoIdp: isCognitoIdp,
                isScimEnabled: userOrgDetails.IsSCIMEnabled,
                isEndUserIDPBanyan: userOrgDetails.IDPName === "BANYAN",
            }
        },
        cacheTime: 0,
    })
}

export function useUpdateDeviceProviderConfig(
    options?: QueryOptions<void, string, DeviceProviderConfig>
) {
    const deviceConfigApi = new DeviceConfigApi()
    const client = useQueryClient()
    return useMutation<void, string, DeviceProviderConfig>({
        ...options,
        mutationFn: (data: DeviceProviderConfig): Promise<void> => {
            let deviceConfig: DeviceConfigRes = {}
            let deviceConfigReq: DeviceConfigReq = {}
            if (data.idpProtocol === ProviderProtocol.SAML) {
                deviceConfig = {
                    RedirectURL: data.redirectUrl,
                    SSOURL: data.ssoUrl,
                    EntityIssuer: data.entityIssuer,
                    CAData: data.caData,
                    UsernameAttr: data.userName,
                    EmailAttr: data.email,
                    GroupsAttr: data.group,
                    GroupsDelim: data.groupDelimiter,
                }
                deviceConfigReq = {
                    DeviceRegIDPName: data.idpName,
                    DeviceRegIDPProtocol: data.idpProtocol,
                    DeviceRegConfig: JSON.stringify(deviceConfig),
                }
            } else if (data.idpProtocol === ProviderProtocol.OIDC) {
                deviceConfig = {
                    RedirectURL: data.redirectUrl,
                    ClientID: data.clientId,
                    ClientSecret: data.clientSecret,
                    IssuerURL: data.issuerUrl,
                }
                deviceConfigReq = {
                    DeviceRegIDPName: data.idpName,
                    DeviceRegIDPProtocol: data.idpProtocol,
                    DeviceRegConfig: JSON.stringify(deviceConfig),
                }
            }

            return deviceConfigApi.updateDeviceConfig(deviceConfigReq)
        },
        onSuccess: () => {
            client.removeQueries(["deviceConfigService.getDeviceProviderConfig"])
            options?.onSuccess?.()
        },
    })
}

export function useDeleteDeviceProviderConfig(options?: QueryOptions<void, unknown, void>) {
    const client = useQueryClient()

    const deviceConfigApi = new DeviceConfigApi()

    return useMutation<void, unknown, void>({
        ...options,
        mutationFn: async () => {
            await deviceConfigApi.deleteDeviceProviderConfig(noneDeviceProviderName)
        },
        onSuccess: () => {
            client.removeQueries(["deviceConfigService.getDeviceProviderConfig"])
            options?.onSuccess?.()
        },
    })
}

export interface DeviceConfig {
    deviceConfig: DeviceProviderConfig
    isAdminBanyanIdp: boolean
    isCognitoIdp: boolean
    isScimEnabled: boolean
    isEndUserIDPBanyan: boolean
}
export interface DeviceProviderConfig {
    ssoUrl: string
    caData: string
    redirectUrl: string
    userName: string
    email: string
    group: string
    groupDelimiter: string
    entityIssuer: string
    clientId: string
    clientSecret: string
    appClientName: string
    issuerUrl: string
    accessKey: string
    secretKey: string
    userPoolId: string
    authDomain: string
    idpProtocol: ProviderProtocol | undefined
    idpName: string
}

export const emptyDeviceConfig: DeviceProviderConfig = {
    ssoUrl: "",
    caData: "",
    redirectUrl: "",
    userName: "",
    email: "",
    group: "",
    groupDelimiter: "",
    entityIssuer: "",
    clientId: "",
    clientSecret: "",
    appClientName: appClientName,
    issuerUrl: "",
    accessKey: "",
    secretKey: "",
    userPoolId: "",
    authDomain: "",
    idpProtocol: undefined,
    idpName: "",
}
