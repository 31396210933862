export const noneDeviceProviderName: string = "NONE"

export enum ProviderName {
    ENTRA_ID = "ENTRA ID",
    OKTA = "OKTA",
    COGNITO = "COGNITO",
    ONE_LOGIN = "ONE LOGIN",
    OTHER = "OTHER",
}

export enum ProviderProtocol {
    UNSET = "",
    SAML = "SAML",
    OIDC = "OIDC",
}

export enum MetadataMethod {
    MANUAL = "MANUAL",
    METADATA_URL = "METADATA_URL",
}
