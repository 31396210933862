import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { EndUserApi, IDPConfigReq, IDPConfigRes } from "../api/EndUser.api"
import { ProviderName, ProviderProtocol } from "./shared/EndUserDeviceConfig"

const appClientName: string = "CSE TrustProvider"
export function useGetEndUserIDPConfig(
    options?: QueryOptions<EndUserConfig>
): UseQueryResult<EndUserConfig> {
    const endUserApi = new EndUserApi()
    return useQuery({
        ...options,
        queryKey: ["endUSerService.getUserIdpConfig"],
        queryFn: async (): Promise<EndUserConfig> => {
            const userOrgDetails = await endUserApi.getUserOrgDetails()
            let idpConfig: IDPConfigRes | undefined
            try {
                idpConfig = JSON.parse(userOrgDetails.IDPConfig)
            } catch {
                idpConfig = undefined
            }

            const isCognitoIdp: boolean = userOrgDetails.IDPName === ProviderName.COGNITO
            if (isCognitoIdp) {
                idpConfig = undefined
            }
            const trustProviderRedirectUrl: string =
                userOrgDetails.TrustproviderURL?.replace(
                    "https://",
                    `https://${userOrgDetails.OrgName}.`
                ) + "/v2/callback" ?? ""
            const redirectUrl: string = trustProviderRedirectUrl
                ? trustProviderRedirectUrl.replace(".trust", ".portal")
                : ""

            return {
                idpConfig: {
                    ssoUrl: idpConfig?.SSOURL || "",
                    redirectUrl: idpConfig?.RedirectURL || redirectUrl,
                    caData: idpConfig?.CAData || "",
                    userName: idpConfig?.UsernameAttr || "",
                    email: idpConfig?.EmailAttr || "",
                    group: idpConfig?.GroupsAttr || "",
                    groupDelimiter: idpConfig?.GroupsDelim || "",
                    entityIssuer: idpConfig?.EntityIssuer || "",
                    metaDataUrl: idpConfig?.MetadataURL || "",
                    clientId: idpConfig?.ClientID || "",
                    clientSecret: idpConfig?.ClientSecret || "",
                    appClientName: appClientName,
                    issuerUrl: idpConfig?.IssuerURL || "",
                    accessKey: idpConfig?.AccessKeyId || "",
                    secretKey: idpConfig?.SecretAccessKey || "",
                    userPoolId: idpConfig?.UserPoolId || "",
                    authDomain: idpConfig?.UserPoolDomain || "",
                    idpName: idpConfig ? userOrgDetails.IDPName : "",
                    idpProtocol: idpConfig
                        ? (userOrgDetails.IDPProto as ProviderProtocol)
                        : ProviderProtocol.UNSET,
                },
                isScimEnabled: userOrgDetails.IsSCIMEnabled,
                isAdminBanyanIdp: userOrgDetails.IsAdminBanyanIDP,
                isCognitoIdp: isCognitoIdp,
                isDeviceIDPBanyan: userOrgDetails.DeviceRegIDPName === "BANYAN",
            }
        },
    })
}

export function useSwitchBanyanIDPToLocalIDP(options?: QueryOptions<void, unknown, void>) {
    const client = useQueryClient()

    const endUserApi = new EndUserApi()

    return useMutation<void, unknown, void>({
        ...options,
        mutationFn: async () => {
            await endUserApi.switchBanyanIDPToLocalIDP()
        },
        onSuccess: () => {
            client.removeQueries(["endUSerService.getUserIdpConfig"])
            options?.onSuccess?.()
        },
    })
}

export function useUpdateIdpConfig(options?: QueryOptions<void, string, IDPConfig>) {
    const endUserApi = new EndUserApi()
    const client = useQueryClient()
    return useMutation<void, string, IDPConfig>({
        ...options,
        mutationFn: (data: IDPConfig): Promise<void> => {
            let idpConfig: IDPConfigRes = {}
            let idpConfigReq: IDPConfigReq = {}
            if (data.idpProtocol === ProviderProtocol.SAML) {
                if (data.metaDataUrl) {
                    idpConfig = {
                        RedirectURL: data.redirectUrl,
                    }
                    idpConfigReq = {
                        IDPName: data.idpName,
                        IDPProtocol: data.idpProtocol,
                        IDPConfig: JSON.stringify(idpConfig),
                        IDPMetadataURL: data.metaDataUrl,
                    }
                } else {
                    idpConfig = {
                        RedirectURL: data.redirectUrl,
                        SSOURL: data.ssoUrl,
                        EntityIssuer: data.entityIssuer,
                        CAData: data.caData,
                        UsernameAttr: data.userName,
                        EmailAttr: data.email,
                        GroupsAttr: data.group,
                        GroupsDelim: data.groupDelimiter,
                    }
                    idpConfigReq = {
                        IDPName: data.idpName,
                        IDPProtocol: data.idpProtocol,
                        IDPConfig: JSON.stringify(idpConfig),
                    }
                }
            } else if (data.idpProtocol === ProviderProtocol.OIDC) {
                idpConfig = {
                    RedirectURL: data.redirectUrl,
                    ClientID: data.clientId,
                    ClientSecret: data.clientSecret,
                    IssuerURL: data.issuerUrl,
                }
                idpConfigReq = {
                    IDPName: data.idpName,
                    IDPProtocol: data.idpProtocol,
                    IDPConfig: JSON.stringify(idpConfig),
                }
            }

            return endUserApi.updateIDPConfig(idpConfigReq)
        },
        onSuccess: () => {
            client.removeQueries(["endUSerService.getUserIdpConfig"])
            options?.onSuccess?.()
        },
    })
}

export interface EndUserConfig {
    idpConfig: IDPConfig
    isScimEnabled: boolean
    isAdminBanyanIdp: boolean
    isCognitoIdp: boolean
    isDeviceIDPBanyan: boolean
}
export interface IDPConfig {
    ssoUrl: string
    caData: string
    redirectUrl: string
    userName: string
    email: string
    group: string
    groupDelimiter: string
    entityIssuer: string
    metaDataUrl: string
    clientId: string
    clientSecret: string
    appClientName: string
    issuerUrl: string
    accessKey: string
    secretKey: string
    userPoolId: string
    authDomain: string
    idpProtocol: ProviderProtocol | undefined
    idpName: string
}

export const emptyIdpConfig = {
    ssoUrl: "",
    caData: "",
    redirectUrl: "",
    userName: "",
    email: "",
    group: "",
    groupDelimiter: "",
    entityIssuer: "",
    metaDataUrl: "",
    clientId: "",
    clientSecret: "",
    appClientName: appClientName,
    issuerUrl: "",
    accessKey: "",
    secretKey: "",
    userPoolId: "",
    authDomain: "",
    idpProtocol: undefined,
    idpName: "",
}
